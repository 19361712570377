<template>
  <div class="w-full pt-5 pb-5">
    <div v-if="hasModuleAccess">
      <template>
        <div class="flex items-center">
          <div class="border-r border-romanSilver h-6 flex">
            <BackButton
              label="Back"
              @onClick="$router.back()"
              variant="secondary"
              class="ml-2"
            />
          </div>
          <h1 class="text-xl text-left font-extrabold mx-4">
            Appraisal Details
          </h1>
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </template>

      <template v-if="isLoading">
        <div class="mt-20 h-full">
          <Loader size="xxs" :loader-image="false" />
        </div>
      </template>

      <template v-else>
        <template>
          <div class="p-5 flex flex-col shadow m-5 rounded-md gap-4">
            <template>
              <div class="flex justify-between items-center">
                <span
                  class="font-bold text-lg leading-5 tracking-normal capitalize text-darkPurple"
                >
                  Appraisal Cycle - {{ agreementData.title }}
                </span>
              </div>
            </template>
            <template>
              <div
                class="border border-dashed bg-white border-romanSilver rounded-md gap-4 p-3 mt-4"
              >
                <div
                  class="flex justify-between items-center w-full gap-4 pb-2 cursor-pointer"
                  @click="handleShowDetails"
                >
                  <span
                    class="font-black text-xs leading-5 uppercase tracking-wider text-romanSilver"
                  >
                    Performance Details
                  </span>
                  <span
                    class="w-9/12 border-t border-solid border-romanSilver h-0"
                  />
                  <Icon
                    :icon-name="showDetails ? 'chevronDown' : 'chevronRight'"
                    class-name="text-darkPurple mx-auto self-center"
                    size="xms"
                  />
                </div>
                <div
                  class="flex flex-col gap-2"
                  :class="{ hidden: !showDetails }"
                >
                  <div class="flex p-3 shadow rounded-md gap-2">
                    <Icon
                      size="s"
                      icon-name="doc-cycle"
                      class-name="text-romanSilver self-start pt-1"
                    />
                    <div class="flex flex-col">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-darkPurple uppercase"
                      >
                        {{ agreementData.cycle }}
                      </span>
                      <span
                        class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                      >
                        {{ agreementData.description }}
                      </span>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mr-2 mt-2 flex-grow"
                    >
                      <Icon
                        size="xs"
                        icon-name="calendar"
                        class-name="text-romanSilver self-start pt-1"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                        >
                          Period
                        </span>
                        <span
                          class="font-bold text-sm leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.start),
                              "MMMM dd, yyyy"
                            )
                          }}
                          -
                          {{
                            $DATEFORMAT(
                              new Date(agreementData.period.end),
                              "MMMM dd, yyyy"
                            )
                          }}
                        </span>
                      </div>
                    </div>
                    <div
                      class="flex p-3 shadow rounded-md gap-2 w-2/5 mt-2 flex-grow"
                      v-for="(cycle, index) in agreementCycles"
                      :key="index"
                      :class="cycle.active ? '' : 'opacity-40'"
                    >
                      <Icon
                        class-name="text-romanSilver self-start pt-1"
                        size="xs"
                        icon-name="reviewicon"
                      />
                      <div class="flex flex-col">
                        <span
                          class="font-black leading-5 text-xs tracking-wide text-blueCrayola uppercase"
                        >
                          {{ cycle.name }}
                        </span>
                        <span
                          class="font-bold text-sm flex leading-6 tracking-wide text-jet uppercase"
                        >
                          {{
                            $DATEFORMAT(new Date(cycle.start), "MMMM dd, yyyy")
                          }}
                          -
                          {{
                            $DATEFORMAT(new Date(cycle.end), "MMMM dd, yyyy")
                          }}
                          <span v-if="extendedCycle">
                            <span
                              class="ml-4 flex"
                              v-if="extendedCycle.appraisalCycleId === cycle.id"
                            >
                              <p
                                class="text-sm uppercase text-mediumSeaGreen italic"
                                style="margin-top: 0.15rem"
                              >
                                Extended
                              </p>
                              <Icon
                                class="ml-3"
                                icon-name="icon-info"
                                size="xs"
                                style="margin-top: -0.15rem"
                              />
                            </span>
                          </span>
                          <span
                            class="font-bold ml-6 text-xs leading-6 flex tracking-wide text-mediumSeaGreen uppercase flex cursor-pointer"
                            @click="viewCycle(cycle.id)"
                          >
                            View
                          </span>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="flex justify-evenly items-center gap-2 w-full">
                    <div class="flex flex-col p-3 shadow rounded-md w-full">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                      >
                        Total Employees
                      </span>
                      <span
                        class="font-semibold leading-5 text-xs tracking-wide text-darkPurple uppercase"
                      >
                        {{ agreementData.totalEmployees }}
                      </span>
                    </div>
                    <div class="flex flex-col p-3 shadow rounded-md w-full">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                      >
                        Number of Submitted
                      </span>
                      <span
                        class="font-semibold leading-5 text-xs tracking-wide text-mediumSeaGreen uppercase"
                      >
                        {{ agreementData.submitted }}
                      </span>
                    </div>
                    <div class="flex flex-col p-3 shadow rounded-md w-full">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                      >
                        Pending
                      </span>
                      <span
                        class="font-semibold leading-5 text-xs tracking-wide text-carrotOrange uppercase"
                      >
                        {{
                          agreementData.totalEmployees - agreementData.submitted
                        }}
                      </span>
                    </div>
                    <div class="flex flex-col p-3 shadow rounded-md w-full">
                      <span
                        class="font-black leading-5 text-xs tracking-wide text-romanSilver uppercase"
                      >
                        Compliance
                      </span>
                      <span
                        class="font-semibold leading-5 text-xs tracking-wide text-desire uppercase"
                      >
                        {{ agreementData.compliance }}
                        %
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </template>
        <template>
          <div class="w-full px-5">
            <div class="w-full">
              <Card class="p-5 pt-0">
                <CardFooter
                  reloadcard
                  @searchResult="handleSearch($event)"
                  showcalendar
                  @reload="reload"
                />
              </Card>
            </div>
            <div style="height: 100%" v-if="isLoading">
              <Loader size="xxs" :loader-image="false" />
            </div>
            <div v-else>
              <Table
                :headers="AppHeader"
                :items="items"
                v-if="items.length > 0 || loadingTable"
                aria-label="advance Table"
                class="w-full"
                :has-checkbox="false"
                :has-number="true"
                :pagination-list="metaData"
                page-sync
                :loading="loadingTable"
                @page="handlePage($event)"
                @itemsPerPage="handleItemsPerPage($event)"
                @rowSelected="displayRow($event)"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.photo">
                    <div>
                      <img
                        class="mr-3"
                        :src="item.data.photo"
                        v-if="item.data.photo"
                        alt="user photo"
                        style="height: 30px; width: 30px; border-radius: 5px"
                      />
                      <div
                        style="height: 30px; width: 30px; border-radius: 5px"
                        class="text-blueCrayola mr-3 flex justify-center border text-center font-semibold pt-1"
                        v-else
                      >
                        {{ $getInitials(`${item.data.appraisee}`) }}
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="item.appraisee"
                    class="flex flex-col whitespace-nowrap font-semibold text-sm leading-5"
                  >
                    <span class="text-darkPurple capitalize">
                      {{ item.data.appraisee }}
                    </span>
                    <span class="text-blueCrayola text-xs font-black uppercase">
                      {{ item.data.designation }}
                    </span>
                  </div>
                  <div v-if="item.functions">
                    <span
                      class="text-center text-sm leading-6 font-normal text-darkPurple"
                    >
                      {{ item.data.functions }}
                    </span>
                  </div>
                  <div v-if="item.manager">
                    <span
                      class="text-center text-sm leading-6 font-normal text-darkPurple"
                    >
                      {{ item.data.manager }}
                    </span>
                  </div>
                  <div v-if="item.selfAppraisal">
                    <span
                      class="text-center text-sm leading-6 font-normal text-darkPurple"
                    >
                      {{
                        item.data.selfAppraisal
                          ? item.data.selfAppraisal
                          : "---"
                      }}
                    </span>
                  </div>
                  <div v-if="item.lineManager">
                    <span
                      class="text-center text-sm leading-6 font-normal text-darkPurple"
                    >
                      {{
                        item.data.lineManager ? item.data.lineManager : "---"
                      }}
                    </span>
                  </div>
                  <div v-if="item.apprScore">
                    <span
                      class="text-center text-sm leading-6 font-normal text-darkPurple"
                    >
                      {{ item.data.apprScore ? item.data.apprScore : "---" }}
                    </span>
                  </div>
                  <div v-if="item.dateSubmitted">
                    <span
                      class="font-normal text-sm leading-6 tracking-wide text-jet capitalize"
                    >
                      {{
                        item.data.dateSubmitted
                          ? $DATEFORMAT(
                              new Date(item.data.dateSubmitted),
                              "MMMM dd, yyyy"
                            )
                          : "---"
                      }}
                    </span>
                  </div>
                  <div v-if="item.id" class="cursor-pointer">
                    <Menu left @click.stop="" top="-100" margin="28" class="">
                      <template v-slot:title>
                        <Icon icon-name="more_icon" size="xs" />
                      </template>
                      <template>
                        <div
                          class="flex flex-col w-52 h-18 p-2 justify-start items-start cursor-pointer"
                        >
                          <div
                            class="hover:bg-ghostWhite w-full rounded-md"
                            @click="
                              $router.push({
                                name: 'PerformanceEmployeeAppraisal',
                                params: {
                                  year: $route.params.year,
                                  userId: item.data.id,
                                  cycle: cycleName,
                                  goalId: goalId,
                                },
                              })
                            "
                          >
                            <div
                              class="flex justify-start items-center p-2 gap-2"
                            >
                              <Icon
                                icon-name="icon-eye"
                                class-name="text-blueCrayola"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                View Appraisal
                              </span>
                            </div>
                          </div>
                          <div
                            class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                            @click="viewStatusReport(item.data.id)"
                          >
                            <div
                              class="flex justify-start items-center p-2 gap-2"
                            >
                              <Icon
                                icon-name="file-document"
                                class-name="text-carrotOrange"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                Performance Reports
                              </span>
                            </div>
                          </div>
                          <div
                            class="hover:bg-ghostWhite w-full rounded-md cursor-pointer"
                            @click="getComments(item.data.id)"
                          >
                            <div
                              class="flex justify-start items-center p-2 gap-2"
                            >
                              <Icon
                                icon-name="comment-outline"
                                class-name="text-carrotOrange"
                                size="xs"
                              />
                              <span
                                class="text-darkPurple text-sm leading-5 font-normal"
                              >
                                Comment
                              </span>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Menu>
                  </div>
                </template>
              </Table>
            </div>
          </div>
        </template>

        <RightSideBar
          v-if="commentModal"
          @submit="sendComment"
          @close="commentModal = false"
          submit="Save"
          button-class="bg-dynamicBackBtn text-white"
        >
          <template v-slot:title>
            <span class="text-darkPurple font-bold text-lg leading-5 p-0"
              >Comments</span
            >
          </template>
          <div class="w-full flex">
            <div class="flex flex-col w-full" style="min-height: 70vh">
              <CommentsBox
                :comments="allComments"
                @markRead="markAsRead($event)"
                :loading="loadComments"
              />
              <div class="-mb-12" style="margin-top: auto">
                <CTextarea
                  placeholder="--Message--"
                  variant="primary"
                  class="mt-2"
                  value=""
                  :row="8"
                  :height="90"
                  :col="30"
                  :input="null"
                  v-model="comment"
                />
              </div>
            </div>
          </div>
        </RightSideBar>
      </template>
    </div>
    <div v-else>
      <ErrorComponent />
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Card from "@scelloo/cloudenly-ui/src/components/card";
import Menu from "@scelloo/cloudenly-ui/src/components/Menu";
import CardFooter from "@/components/CardFooter";
import RightSideBar from "@/components/RightSideBar";
import CommentsBox from "@/components/commentsBox";
import paramsMixin from "@/utilities/paramsMixin";
import timeFormatMixin from "@/utilities/timeFormatMixin";
import ErrorComponent from "@/modules/Admin/error403";

export default {
  mixins: [paramsMixin, timeFormatMixin],
  components: {
    BackButton,
    Breadcrumb,
    Table,
    RightSideBar,
    CommentsBox,
    ErrorComponent,
    CTextarea,
    Card,
    Menu,
    CardFooter,
  },
  data() {
    return {
      isLoading: true,
      hasModuleAccess: false,
      showDetails: true,
      loadingTable: true,
      breadcrumbs: [
        {
          disabled: false,
          text: "Performance",
          href: "Performance",
          id: "Performance",
        },
        {
          disabled: false,
          text: "Performance Appraisal",
          href: "Performance Appraisal",
          id: "PerformanceAppraisal",
        },
        {
          disabled: false,
          text: "Appraisal Details",
          href: "AppraisalDetails",
          id: "AppraisalDetails",
        },
      ],
      AppHeader: [
        { title: "", value: "photo", width: "6" },
        { title: "Name", value: "appraisee" },
        { title: "Function", value: "functions" },
        { title: "Manager", value: "manager" },
        { title: "Self Appraisal", value: "selfAppraisal" },
        { title: "Line Manager", value: "lineManager" },
        { title: "Appr. flag", value: "apprScore" },
        { title: "Date Submitted", value: "dateSubmitted" },
        { title: "", value: "id", image: true },
      ],
      agreementData: {
        title: null,
        cycle: null,
        description: null,
        period: { start: null, end: null },
        totalEmployees: null,
        submitted: null,
        compliance: null,
      },
      agreementCycles: [],
      items: [],
      cyclesId: [],
      finalAppraisal: false,
      agreementId: "",
      allComments: [],
      comment: "",
      loadComments: true,
      receiverId: "",
      commentModal: false,
      cycleName: "",
      goalId: "",
      extendedCycle: {},
      activeCycle: this.$route.params.cycleId,
      searchQuery: "",
      metaData: {},
      itemsPerPage: 50,
      numberOfPage: 1,
    };
  },
  methods: {
    async viewStatusReport(id) {
      try {
        await this.$handlePrivilege(
          "performanceAppraisals",
          "viewStatusReport"
        );
        this.$router.push({
          name: "EmployeeStatusReports",
          params: {
            id,
            year: this.$route.params.year,
          },
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },

    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },

    queryUpdate() {
      this.loadingTable = true;
      const search = this.searchQuery ? `${this.searchQuery}` : "";
      const pageNumber = this.numberOfPage
        ? `&page=${this.numberOfPage}`
        : `&page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getSubmittedAppraisals(`${pageNumber}${itemPage}${search}`);
    },

    handleSearch(value) {
      this.searchQuery = `&search=${value}`;
      this.loadingTable = true;
      this.items = [];
      this.getSubmittedAppraisals(this.searchQuery);
    },

    viewCycle(value) {
      this.activeCycle = value;
      this.items = [];
      this.loadingTable = true;
      this.getSubmittedAppraisals("");
    },

    handleShowDetails() {
      this.showDetails = !this.showDetails;
    },

    reload() {
      this.loadingTable = true;
      this.items = [];
      this.getSubmittedAppraisals("");
    },

    getAgreements() {
      this.$_getOrgPerformanceAgreement().then((response) => {
        const result = response.data.data;

        result.forEach((info) => {
          if (info.agreementDetail.year === Number(this.$route.params.year)) {
            this.agreementData.title =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.cycle =
              info.agreementDetail.goal_performance_templates.agreement_title;
            this.agreementData.period.start = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .start
            ).toISOString();
            this.agreementData.period.end = this.setDates(
              info.agreementDetail.goal_performance_templates.appraisal_cycle
                .end
            ).toISOString();

            this.goalId = info.agreementDetail.id;
          }
        });
      });
    },

    getCycleTabs() {
      const today = new Date();

      this.$_getAppraisalCycle(this.appraisalQuery()).then((response) => {
        const appraisal = response.data.AppraisalCycle;
        const { extension } = response.data;

        this.extendedCycle = extension;

        if (appraisal[0].cycles.length > 0) {
          const appraisalCycle = appraisal[0].cycles.sort(
            (a, b) =>
              this.setDates(a.appraisal_starts) -
              this.setDates(b.appraisal_starts)
          );
          appraisalCycle.map((cycle) => {
            const cycleStartDate = new Date(
              this.setDates(cycle.appraisal_starts).toISOString()
            );

            if (cycle.id === this.$route.params.cycleId) {
              this.cycleName = cycle.name;
            }

            this.cyclesId.push(cycle.id);

            const cycleEndDate = new Date(
              this.setDates(cycle.appraisal_ends).toISOString()
            );

            cycleEndDate.setDate(cycleEndDate.getDate() + 1);

            this.agreementCycles.push({
              name: cycle.name,
              start: this.setDates(cycle.appraisal_starts).toISOString(),
              end: this.setDates(cycle.appraisal_ends).toISOString(),
              active: today >= cycleStartDate && today <= cycleEndDate,
              id: cycle.id,
            });
            return {};
          });
        }
        this.getSubmittedAppraisals("");
      });
    },

    getSubmittedAppraisals(params) {
      const tabIndex = this.cyclesId.indexOf(this.activeCycle, 0);

      if (tabIndex === this.cyclesId.length - 1) {
        this.finalAppraisal = true;
      } else {
        this.finalAppraisal = false;
      }

      const query = `?cycle=${this.activeCycle}&year=${this.$route.params.year}`;
      this.$_getOrgSubmittedAppraisal(query, params)
        .then((response) => {
          const responseData = response.data.data;
          this.metaData = response.data.meta;

          responseData.forEach((data) => {
            if (data.agreementDetail.year === Number(this.$route.params.year)) {
              const employeesData = data.employees;
              this.agreementData.totalEmployees = data.totalEmployees;
              this.agreementData.submitted = data.SubmittedAppraisal;
              this.agreementData.compliance = data.compliance;

              employeesData.forEach((employee) => {
                this.items.push({
                  id: employee.user.userId,
                  appraisee: `${employee.user.fname} ${employee.user.lname}`,
                  photo: employee.user.photo,
                  designation: employee.user.designation,
                  manager: `${employee.user.lineManager.fname} ${employee.user.lineManager.lname}`,
                  functions: employee.user.function,
                  selfAppraisal: this.finalAppraisal
                    ? employee.selfAppraisal
                    : employee.employeeRank,
                  lineManager: this.finalAppraisal
                    ? employee.managerAppraisal
                    : employee.managerRank,
                  apprScore: this.finalAppraisal
                    ? employee.appraisalScore
                    : employee.flag,
                  dateSubmitted: employee.dateSubmitted,
                  agreementId: data.agreementDetail.id,
                });
              });
            }
          });
          this.isLoading = false;
          this.loadingTable = false;
        })
        .catch(() => {
          this.isLoading = false;
          this.loadingTable = false;
        });
    },

    formatCommentTime(date) {
      const newDate = new Date(date);
      const hours = newDate.getUTCHours();
      const minutes = newDate.getMinutes();
      return `${1 + ((hours - 1) % 12)}:${minutes
        .toString()
        .padStart(2, "0")} ${hours > 11 ? "PM" : "AM"}`;
    },

    formatCommentDate(date) {
      const newDate = new Date(date);
      return newDate.toDateString();
    },

    getComments(userId) {
      this.commentModal = true;
      this.allComments = [];
      this.receiverId = userId;
      this.loadComments = true;

      this.$_getEmployeeAgreement(userId).then((response) => {
        const myAgreements = response.data.EmployeePerformanceAgreements;

        myAgreements.map((agreement) => {
          if (Number(agreement.year) === Number(this.$route.params.year)) {
            this.agreementId = agreement.id;

            this.$_getAgreementComments(agreement.id).then((resp) => {
              const { comments } = resp.data;
              comments.forEach((comment) => {
                this.allComments.push({
                  message: comment.comment,
                  time: this.formatCommentTime(comment.date),
                  date: this.formatCommentDate(comment.date),
                  commentId: comment.id,
                  isRead: comment.readStatus,
                  receiver: comment.receiver,
                  sender: comment.sender,
                });

                this.loadComments = false;
              });
            });
          }
          return {};
        });
      });
    },

    async sendComment() {
      try {
        await this.$handlePrivilege(
          "performanceAppraisals",
          "makeComment"
        );
        const payload = {
          performanceAgreementId: this.agreementId,
          senderId: this.$AuthUser.id,
          receiverId: this.receiverId,
          comment: this.comment,
          sendTo: "admin",
        };
        this.$_postAgreementComments(payload).then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.receiverId);
          this.comment = "";
        });
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },

    markAsRead(commentId) {
      const payload = {
        commentId,
        readStatus: true,
      };
      this.$_markAsRead(payload)
        .then((response) => {
          this.$toasted.success(response.data.message, { duration: 5000 });
          this.getComments(this.receiverId);
        })
        .catch((err) => {
          this.$toasted.error(err.response.data.message, { duration: 5000 });
        });
    },
  },

  async mounted() {
    try {
      await this.$handlePrivilege(
        "performanceAppraisals",
        "viewEmployeeAppraisal"
      );
      this.hasModuleAccess = true;
      this.getAgreements();
      this.getCycleTabs();
    } catch (error) {
      this.hasModuleAccess = false;
    }
  },
};
</script>
